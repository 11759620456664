import React, { useState } from 'react';
import HQChart from 'hqchart';
import dayJson from "../json/kline-day";
import dayJsonUpdate from "../json/kline-day-update";
import minuteJson from "../json/kline-minute";
import minuteJsonUpdate from "../json/kline-minute-update";
import { getHistoryKline } from '../utils/kline-util';
import queryString from 'query-string';
import dayjs from 'dayjs'

/**
 * 备注：修改K线图KLineTooltip的显示参数
 * 修改node_modules源码中 hqchart > lib > umychart.vue.js 中 
 * 1、3959行 var lineCount=6;//显示函数
 * 2、4004-4040行注释
 * 详细参考项目根目录 lib > umychart.vue.js
 */

let chart;
var timeConnect = 0;
// 最新K线数据
var stockItem = null;
const newKlineData = (klineData) => {
    // 最新K线数据
    let newData = klineData.data[klineData.data.length - 1];
    stockItem={ symbol: klineData.symbol, name: klineData.name};
    stockItem.time=newData[8];
    stockItem.date=newData[0];
    stockItem.price=newData[5];
    stockItem.yclose=0;
    stockItem.open=newData[2];
    stockItem.high=newData[3];
    stockItem.low=newData[4];
    stockItem.vol=0; //单位是股
    stockItem.amount=0;
}
const values = queryString.parse(window.location.search);
class Kline extends React.Component {

    constructor(props) { //构造函数
        super(props);
        this.initCanvas = this.initCanvas.bind(this);
        
        // 启动WS
        this.websocketKline()
        
        this.state = {
            Symbol: values.symbol,
            Kline: {
                JSChart: null,
                Option: {
                    Symbol:'',
                    Type: '历史K线图', 
                    SplashTitle: 'Loading...',
                    Language:'EN',
                    
                    Windows: //窗口指标
                    [
                        {Index:"MA",Modify: false, Change: false}, 
                        {Index:"MACD",Modify: false, Change: false},
                    ], 

                    IsAutoUpdate:true,                  //是自动更新数据 （使用ws去掉定时器更新)
                    AutoUpdateFrequency: 10000,          //数据更新频率

                    IsShowRightMenu:false,          //右键菜单
                    IsCorssOnlyDrawKLine:true, //十字光标只能在K线上
                    IsShowCorssCursorInfo:true,
                    IsClickShowCorssCursor:true, //手势点击出现十字光标
                    EnableScrollUpDown:true, //允许手势上下操作滚动页面

                    Border: //边框
                    {
                        Left:0,         //左边间距
                        Right:0,       //右边间距
                        Bottom:20,      //底部间距
                        Top:25,          //顶部间距
                    },
                    KLineTitle: //标题设置
                    {
                        IsShowName:true,       //不显示股票名称
                        IsShowSettingInfo:false //不显示周期/复权
                    },
                    EnableFlowCapital:
                    {
                        BIT:true,  //强制下载数字货币流通股
                    },
                    ExtendChart:    //扩展图形
                    [
                        {Name:'KLineTooltip' }  //增加手机端tooltip
                    ],
                    Frame:  //子框架设置
                    [
                        {SplitCount:3,StringFormat:0, IsShowLeftText:false,Custom: [ { Type:0,Position:'right'} ]},
                        {SplitCount:2,StringFormat:0, IsShowLeftText:false},
                        {SplitCount:2,StringFormat:0, IsShowLeftText:false}
                    ],
            
                    IsApiPeriod: true,                          //false=周期数据是前端合并, true=周期和复权数据都使用后台api数据
                    KLine:
                    {
                        DragMode:1,                             //拖拽模式 0 禁止拖拽 1 数据拖拽 2 区间选择
                        Right:1,                                //复权 0 不复权 1 前复权 2 后复权
                        Period: 20001,                          // [30001-32000) 秒周期
                                                                // 0=日线 1=周线 2=月线 3=年线 9=季线  [40001-50000) 自定义日线
                                                                // 4=1分钟 5=5分钟 6=15分钟 7=30分钟 8=60分钟 11=120分钟 12=240分钟 [20001-30000) 自定义分钟
                        MaxReqeustDataCount:1000,               //数据个数
                        MaxRequestMinuteDayCount:10,            //分钟数据取5天
                        PageSize:50,                            //一屏显示多少数据
                        IsShowTooltip:false,                    //是否显示K线提示信息
                        DrawType:0,                             //K线类型 0=实心K线柱子 1=收盘价线 2=美国线 3=空心K线柱子 4=收盘价面积图
                    },
                    NetworkFilter:  async function(data, callback)	//网络协议回调
                    {
                        console.log('[NetworkFilter] data', data);
                        data.PreventDefault=true;	//设置hqchart不请求数据
                        switch(data.Name)
                        {
                            case "KLineChartContainer::RequestHistoryData":                 //日线全量数据下载
                                console.log("-------------------- day --------------------");
                                var hqchartdata = await getHistoryKline(values.symbol, Number(values.period), 1500);  //请求数据， 把数据转成hqchart格式数据
                                // var hqchartdata = dayJson;            //本地JSON文件数据
                                newKlineData(hqchartdata);
                                console.log(hqchartdata);
                                callback(hqchartdata);
                                break;
                            case 'KLineChartContainer::RequestRealtimeData':                //日线实时数据更新
                                console.log("-------------------- day_update --------------------");
                                var hqchartdata = await getHistoryKline(values.symbol, Number(values.period), 2);    //请求数据， 把数据转成hqchart格式数据
                                // var hqchartdata = dayJsonUpdate;      //本地JSON文件数据
                                newKlineData(hqchartdata);
                                console.log(hqchartdata);
                                callback(hqchartdata);
                                break;
                            case "KLineChartContainer::ReqeustHistoryMinuteData":               //分钟全量数据下载
                                console.log("-------------------- minute --------------------");
                                var hqchartdata = await getHistoryKline(values.symbol, Number(values.period), 1500); //请求数据， 把数据转成hqchart格式数据
                                // var hqchartdata = minuteJson;       // 本地JSON文件数据
                                newKlineData(hqchartdata);
                                console.log(hqchartdata);
                                callback(hqchartdata);
                                break;
                            case 'KLineChartContainer::RequestMinuteRealtimeData':              //分钟增量数据更新
                                console.log("-------------------- minute_update --------------------");
                                var hqchartdata = await getHistoryKline(values.symbol, Number(values.period), 2);     //请求数据， 把数据转成hqchart格式数据
                                // var hqchartdata = minuteJsonUpdate;     // 本地JSON文件数据
                                newKlineData(hqchartdata);
                                console.log(hqchartdata);
                                callback(hqchartdata);
                                break;
                        }
                    }
                }
            }
        }

        // K线图、分时图切换
        if (values.type === "minute") {
            this.state.Kline.Option.Windows = [{
                Index:"EMPTY", //设置为空指标
                Modify:false,Change:false,
                TitleHeight:0 //去掉指标信息标题的高度
            }]
            this.state.Kline.Option.EnableZoomUpDown = {
                  Wheel: false,     // 鼠标滚轴是否允许缩放 true/false
                  Keyboard: false,  // 键盘上下是否允许缩放 true/false
                  Touch: false,     // 手机手势是否允许缩放 true/false
            }
            this.state.Kline.Option.KLine.DrawType = 4
            this.state.Kline.Option.KLine.DragMode = 0

            // 分时图开盘收盘
            if (values.openTime && values.closeTime) {
                let timestamp = Date.parse(new Date());
                let open_time  = dayjs(timestamp).format('YYYY-MM-DD ') + values.openTime + ":00";
                let openTimestamp = new Date(open_time).getTime();
                let close_time  = dayjs(timestamp).format('YYYY-MM-DD ') + values.closeTime + ":00";
                let closeTimestamp = new Date(close_time).getTime();

                // 根据开盘时间和收盘时间计算分时图总共显示多少条数据
                let second = closeTimestamp - openTimestamp;
                let klineSize = Math.floor(second / 1000 / 60);

                let secondNow = timestamp - openTimestamp
                let klineShowSize = Math.floor(secondNow / 1000 / 60);

                this.state.Kline.Option.KLine.PageSize = klineSize
                this.state.Kline.Option.KLine.RightSpaceCount = klineSize - klineShowSize
            } else {
                this.state.Kline.Option.KLine.PageSize = 1440
            }
        } else if (values.type === "kline") {}
    }

    // 初始化
    initCanvas() {
        if (this.state.Kline.JSChart) return;

        this.state.Kline.Option.Symbol=this.state.Symbol;
        HQChart.Chart.JSChart.GetResource().FrameLogo.Text=null;

        // 红涨绿跌
        if (values.kcolor) {
            const red = "#EE1515";
            const green = "#199E00";
            var whiteStyle = HQChart.Chart.HQChartStyle.GetStyleConfig(HQChart.Chart.STYLE_TYPE_ID.WHITE_ID); //白色风格
            if (values.kcolor == 0) {
                whiteStyle.UpBarColor = green;	        //修改阳线柱子颜色【上涨】
                whiteStyle.DownBarColor = red;	    //修改阴线柱子颜色【下跌】
            } else {
                whiteStyle.UpBarColor = red;	        //修改阳线柱子颜色【上涨】
                whiteStyle.DownBarColor = green;	    //修改阴线柱子颜色【下跌】
            }
            HQChart.Chart.JSChart.SetStyle(whiteStyle);  //设置全局颜色配置
        }

        // 设置小数位
        if (values.decimal) {
            HQChart.Chart.MARKET_SUFFIX_NAME.GetDefaultDecimal=(symbol)=> { 
                return Number(values.decimal); 
            }
        }
        HQChart.Chart.MARKET_SUFFIX_NAME.GetMarketStatus = (symbol) => {
            return 2; //一直交易
        }

        //禁用日志
        HQChart.Chart.JSConsole.Chart.Log=()=>{ }
        HQChart.Chart.JSConsole.Complier.Log=()=>{ }
       
        chart=HQChart.Chart.JSChart.Init(document.getElementById("time_graph_canvas"));
        chart.SetOption(this.state.Kline.Option);
        this.state.Kline.JSChart=chart;
    }

    // websocket 数据
    websocketKline() {
        if(typeof(WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        }else{
            console.log("您的浏览器支持WebSocket");
        }
        
        let this_ = this;
        let websocket  = new WebSocket("wss://tradeapi-cc.gwskfs.com/api/gw/noauth/websocks/mt5sock");
        //打开事件
        websocket.onopen = function () {
            console.log("websocket已打开");

            // 发送获取产品信息
            let sendInfo = { cmd: 10001, sbl: values.symbol}
            websocket.send(JSON.stringify(sendInfo));

            // 发送获取最后一口报价
            websocket.send(JSON.stringify({ cmd: 10007, sbl: values.symbol}));
        }
        //发现消息进入
        websocket.onmessage = function (msg) {
            // console.log("websocket已连接");
            // console.log(msg.data);  // 第一次进去会显示：连接成功

            let wsData = JSON.parse(msg.data);
            if(wsData != undefined && (wsData.cmd === 51001 || wsData.cmd === 10008) && wsData.sbl === values.symbol) {
                if (stockItem != null && chart) {
                    stockItem.price = Number(wsData.bid);
                    if (stockItem.high < stockItem.price) {
                        stockItem.high = stockItem.price;
                    }
                    let utime = wsData.utime - 8*60*60
                    stockItem.date = Number(dayjs(utime * 1000).format('YYYYMMDD'));
                    stockItem.time = Number(dayjs(utime * 1000).format('HHmm'));
                    var hqChartData={code:0, stock:[stockItem] }; 
                    chart.JSChartContainer.RecvRealtimeData(hqChartData);
                }
            }

            if (wsData != undefined && wsData.cmd === 10002) {
                console.log('品种信息', wsData.data)
            }
        }
        //关闭事件
        websocket.onclose = function() {
            console.log("websocket已关闭");
            this_.reconnect();
        };
        //发生了错误事件
        websocket.onerror = function() {
            console.log("websocket发生了错误!");
        }
    }

    // WS 重新连接
    async reconnect() {
        // 休眠5秒，重新连接
        await (new Promise((resolve) => setTimeout(resolve, 5000)))

        timeConnect++;
        console.log("第" + timeConnect + "次重连");
        // 进行重连
        this.websocketKline();
    }
    
    componentDidMount() {
        this.initCanvas()
    }
    
    componentDidUpdate() {
        this.initCanvas()
    }

    // 切换周期
    // selectPeriod(period) {
    //     chart.ChangePeriod(period) 
    // }

    render() {
        var chartHeight = window.innerHeight-52;
        var chartWidth = window.innerWidth-0;
        var styleText = {
            width: chartWidth+'px', 
            height: chartHeight+'px',
        };
        return (
            <>
                {/* <span onClick={()=>this.selectPeriod(20001)}>1分钟</span>&nbsp;
                <span onClick={()=>this.selectPeriod(20005)}>5分钟</span>&nbsp;
                <span onClick={()=>this.selectPeriod(20015)}>15分钟</span>&nbsp;
                <span onClick={()=>this.selectPeriod(20030)}>30分钟</span>&nbsp;
                <span onClick={()=>this.selectPeriod(20060)}>60分钟</span>&nbsp;
                <span onClick={()=>this.selectPeriod(0)}>1天</span>&nbsp;
                <span onClick={()=>this.selectPeriod(1)}>1周</span>&nbsp;
                <span onClick={()=>this.selectPeriod(2)}>1月</span>&nbsp;
                <span onClick={()=>this.selectPeriod(3)}>1年</span> */}

                <div style={styleText} id="time_graph_canvas">
                </div>
            </>
        )
      }
}

export default Kline;