import axios from 'axios';

// export const baseUrl = "";
export const baseUrl = "https://cckl.gneia.com";
// export const baseUrl = "https://mckline.zzsmgs55.com:9443";

axios.defaults.baseURL = baseUrl

/** 请求历史K线数据 */
export const historyKline =  (params) => {
    return axios.post(
        '/TradeInfo/ChartRequestBinary',
        { ...params }
    )
}
