import { Base64 } from 'js-base64';
import DecimalOld from 'decimal.js';
import { historyKline } from './axios-api';
import minuteTodayJson from "../json/minute-today";
import dayjs from 'dayjs'

export async function getHistoryKline(symbol, period, size) {
    const d = {
        s: symbol,
        p: period,
        l: size,
        t: "",
        e: "",
    }
    var params = Base64.btoa(quoteUtil.stringToBin(d.s, 12) 
                            + quoteUtil.intToBin(d.p, 4) 
                            + quoteUtil.intToBin(d.l, 4) 
                            + quoteUtil.timeToBin(d.t, 4) 
                            + quoteUtil.timeToBin(d.e, 4));
    var klineData = { data: [], symbol: symbol, name: symbol, code: 0};
    await historyKline({b: params}
        ).then(function (resp) {
            let yclose = 0;
            const result = resp.data.map((item) => {
                const blob = Base64.atob(item.b)
                const timeStamp = (base64ToInt(blob.slice(20))[0] - 8*60*60) * 1000

                const open = base64ToFloat32(blob.slice(0, 4))[0];
                const close = base64ToFloat32(blob.slice(4, 8))[0];
                const high = base64ToFloat32(blob.slice(8, 12))[0];
                const low = base64ToFloat32(blob.slice(12, 16))[0];
                const vol = base64ToFloat32(blob.slice(16, 20))[0];
                const dateVal = Number(dayjs(timeStamp).format('YYYYMMDD'));
                const timeVal = Number(dayjs(timeStamp).format('HHmm'));

                const dataInfo = [
                    dateVal,
                    yclose,   //前收盘价
                    open,   //开盘价
                    high,  //最高
                    low, //最低
                    close,   //收盘价
                    undefined,   // 成交量
                    undefined,   // 成交金额
                    timeVal,
                ]
                yclose = close
                return dataInfo;
            })
            if(result.length <= 3){
                klineData.ver = result.length
            }
            klineData.data = result;
        })
        .catch(function (error) {
            console.log(error);
            klineData.data = [];
        })

    return klineData;
}

export async function getHistoryMinute(symbol, period, size, openTimestamp) {
    const d = {
        s: symbol,
        p: period,
        l: size,
        t: "",
        e: "",
    }
    var params = Base64.btoa(quoteUtil.stringToBin(d.s, 12) 
                            + quoteUtil.intToBin(d.p, 4) 
                            + quoteUtil.intToBin(d.l, 4) 
                            + quoteUtil.timeToBin(d.t, 4) 
                            + quoteUtil.timeToBin(d.e, 4));
    var klineData = { stock: [], code: 0 };
    await historyKline({b: params}
        ).then(function (resp) {
            let stockData = {};
            const result = [];
            resp.data.forEach((item, index) => {
                const blob = Base64.atob(item.b);
                const timeStamp = (base64ToInt(blob.slice(20))[0] - 8*60*60) * 1000;

                if(index === resp.data.length-1) {
                    stockData = {
                        time: Number(dayjs(timeStamp).format('HHmm')),
                        date: Number(dayjs(timeStamp).format('YYYYMMDD')),
                        price: base64ToFloat32(blob.slice(4, 8))[0],
                        open: base64ToFloat32(blob.slice(0, 4))[0],
                        yclose: 0,
                        high: base64ToFloat32(blob.slice(8, 12))[0],
                        low: base64ToFloat32(blob.slice(12, 16))[0],
                        vol: undefined,
                        amount: undefined,
                        symbol: symbol+".ET",
                        name: symbol
                    };
                    // 设置昨收价格
                    if (result.length > 0) {
                        stockData.yclose = result[0].open;
                    }
                }

                // 过滤今天时间
                let hhmm = Number(dayjs(timeStamp).format('HHmm'));
                if (dayjs(timeStamp).format('YYYYMMDD') == dayjs().format('YYYYMMDD') && hhmm >= openTimestamp) {
                    result.push({
                        price: base64ToFloat32(blob.slice(4, 8))[0],
                        open: base64ToFloat32(blob.slice(0, 4))[0],
                        high: base64ToFloat32(blob.slice(8, 12))[0],
                        low: base64ToFloat32(blob.slice(12, 16))[0],
                        vol: undefined,
                        amount: undefined,
                        time: hhmm,
                        avprice: undefined,
                        increase: undefined,
                        risefall: undefined
                    });
                }
            })
            stockData.minute = result;
            klineData.stock[0] = stockData;
        })
        .catch(function (error) {
            console.log(error);
        })
    return klineData;
}

// toFixed
export function toFixed(val, num = 2) {
    if (isNaN(val) || !val) val = 0
    return Number(Decimal(val).toFixed(num))
}

export function Decimal(v) {
    try {
      if (!v) v = 0
      return new DecimalOld(v)
    } catch {
      return new DecimalOld(NaN)
    }
}

export function base64ToFloat32(blob) {
    const fLen = blob.length / Float32Array.BYTES_PER_ELEMENT // How many floats can be made, but be even
    const dView = new DataView(new ArrayBuffer(Float32Array.BYTES_PER_ELEMENT)) // ArrayBuffer/DataView to convert 4 bytes into 1 float.
    const fAry = new Float32Array(fLen) // Final Output at the correct size
    let p = 0 // Position
    for (let j = 0; j < fLen; j++) {
      p = j * 4
      dView.setUint8(0, blob.charCodeAt(p))
      dView.setUint8(1, blob.charCodeAt(p + 1))
      dView.setUint8(2, blob.charCodeAt(p + 2))
      dView.setUint8(3, blob.charCodeAt(p + 3))
      fAry[j] = dView.getFloat32(0, true)
    }
    return fAry
}

export function base64ToInt(blob) {
    const fLen = blob.length / Int32Array.BYTES_PER_ELEMENT // How many floats can be made, but be even
    const dView = new DataView(new ArrayBuffer(Int32Array.BYTES_PER_ELEMENT)) // ArrayBuffer/DataView to convert 4 bytes into 1 float.
    const fAry = new Int32Array(fLen) // Final Output at the correct size
    let p = 0 // Position
    for (let j = 0; j < fLen; j++) {
      p = j * 4
      dView.setInt8(0, blob.charCodeAt(p))
      dView.setInt8(1, blob.charCodeAt(p + 1))
      dView.setInt8(2, blob.charCodeAt(p + 2))
      dView.setInt8(3, blob.charCodeAt(p + 3))
      fAry[j] = dView.getInt32(0, true)
    }
    return fAry
}

// 获取ws行情数据信息
export const quoteUtil = {
    base64ToArrayBuffer(data) {
        var binary_string = Base64.atob(data);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    },
    strip(num, precision = 6) {
        return +parseFloat(num.toPrecision(precision)); // 把错误数据转正
    },
    getSymbol(data) {
        // 获取种类名称
        var binary_string = Base64.atob(data);
        return binary_string.slice(0, 12).replace(/\0/g, '');
    },
    getPrice(quoteBuffer, offset = 12) {
        return new Float32Array(quoteBuffer, offset);
    },
    getTime(quoteBuffer, offset = 20) {
        let t = new Int32Array(quoteBuffer, offset);
        return +new Date('1970/01/01').setSeconds(t[0]);
    },
    getQuote(symbol, data) {
        // 返回行情数据
        if (typeof data == 'undefined') {
            data = symbol;
            symbol = this.getSymbol(data);
        }
        const quoteBuffer = this.base64ToArrayBuffer(data);
        const priceArr = this.getPrice(quoteBuffer);
        const time = this.getTime(quoteBuffer);
        return {
            symbol,
            bid: this.strip(priceArr[0]),
            ask: this.strip(priceArr[1]),
            time,
            diff: 0,  // 初始值
        }
    },
    getChart(data) {
        const buffer = this.base64ToArrayBuffer(data)
        const chart = this.getPrice(buffer, 0);
        const time = this.getTime(buffer);
        let res = {
            t: time,
        }
        const arr = ['o', 'c', 'h', 'l', 'w'];
        arr.forEach((item, i) => {
            res[item] = this.strip(chart[i]);
        });
        return res;
    },
    stringToBin(data, len) {
        data += '';
        let bytes = new Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = data.charCodeAt(i);
        }
        return String.fromCharCode.apply(null, bytes);
    },
    intToBin(data) {
        let bytes = new Array(4);
        for (let i = 3; i >= 0; i--) {
            bytes[i] = (data >> (8 * i)) & 0xff;
        }
        return String.fromCharCode.apply(null, bytes);
    },
    timeToBin(data) {
        // 时间（秒数）转二进制
        var at = new Date(data);
        var date = (Date.parse(at) / 1000);
        return this.intToBin(date);
    }
}