import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Minute from './kline/hqchart-minute';
import Kline from './kline/hqchart-kline';
import queryString from 'query-string';

const root = ReactDOM.createRoot(document.getElementById('root'));
const values = queryString.parse(window.location.search);

root.render(
  <React.StrictMode>
    { values.type === "minute" ? <Minute></Minute> : <Kline></Kline> }
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
