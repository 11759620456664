import React, { useState } from 'react';
import HQChart from 'hqchart';
import minuteTodayJson from "../json/minute-today";
import { getHistoryMinute } from '../utils/kline-util';
import queryString from 'query-string';
import dayjs from 'dayjs'

let chart;
var timeConnect = 0;
// 最新K线数据
let this_
var stockItem = null;
let minuteData;
var UpdateMinuteData;
var MinuteChart;

let timeSplit;
let minuetCount;
let openTime;

//交易时间段
const TIME_SPLIT =
[
    { Start:0, End:2359} //跨天要分开写
];
//X轴刻度显示配置
const MINUTE_X_COORDINATE=
{
    Full:   //完整模式
    [
        [0, 1, "RGB(200,200,200)", "00:00"],    //0=数据索引 1=暂时为用到 2=线段和字颜色 3=刻度显示的文字
        [360, 1, "RGB(200,200,200)", "06:00"],
        [720, 1, "RGB(200,200,200)", "12:00"],
        [1080, 1, "RGB(200,200,200)", "18:00"],
        [1439, 1, "RGB(200,200,200)", "23:59"],
        
    ],
    // Simple: //简洁模式
    // [
    //     [0, 1, "RGB(200,200,200)", "00:00"],    //0=数据索引 1=暂时为用到 2=线段和字颜色 3=刻度显示的文字
    //     [360, 1, "RGB(200,200,200)", "06:00"],
    //     [720, 1, "RGB(200,200,200)", "12:00"],
    //     [1080, 1, "RGB(200,200,200)", "18:00"],
    //     [1439, 1, "RGB(200,200,200)", "23:59"],
    // ],
    // Min:   //最小模式     
    // [
    //     [0, 1, "RGB(200,200,200)", "00:00"],
    //     [720, 1, "RGB(200,200,200)", "12:00"],
    //     [1439, 1, "RGB(200,200,200)", "23:59"],
    // ],

    Count: 1440,
    MiddleCount: 720,   //中心的位置

    GetData: function (width) 
    {
        // if (width < 200) return this.Min;
        // else if (width < 450) return this.Simple;

        return this.Full;
    }
}
const values = queryString.parse(window.location.search);
class Kline extends React.Component {

    constructor(props) { //构造函数
        super(props);
        this.initCanvas = this.initCanvas.bind(this);
        
        this_ = this;
        // 启动WS
        this.websocketKline();
        
        this.state = {
            Symbol: values.symbol+".ET",
            Kline: {
                JSChart: null,
                Option: {
                    Symbol:'',
                    Type: '分钟走势图', 
                    SplashTitle: 'Loading...',
                    Language:'EN',
                    
                    Windows: //窗口指标
                    [], 

                    DayCount:1,                     //1 最新交易日数据 >1 多日走势图
                    IsAutoUpdate:true,                  //是自动更新数据 （使用ws去掉定时器更新)
                    AutoUpdateFrequency: 10000,          //数据更新频率

                    IsShowRightMenu:false,          //右键菜单
                    IsCorssOnlyDrawKLine:true, //十字光标只能在K线上
                    IsShowCorssCursorInfo:true,
                    IsClickShowCorssCursor:true, //手势点击出现十字光标
                    EnableScrollUpDown:true, //允许手势上下操作滚动页面

                    MinuteLine:
                    {
                        IsDrawAreaPrice: true,      //是否画价格面积图
                        IsShowAveragePrice: true,	//是否显示均线
                    },

                    MinuteTitle: //标题设置
                    {
                        IsShowName:true,       //不显示股票名称
                        IsShowSettingInfo:true //不显示周期/复权
                    },

                    Border: //边框
                    {
                        Left:0,         //左边间距
                        Right:0,       //右边间距
                        Bottom:20,      //底部间距
                        Top:25          //顶部间距
                    },
                    ExtendChart:    //扩展图形
                    [
                        {Name:'MinuteTooltip' }  //增加手机端tooltip
                    ],
                    Frame:  //子框架设置
                    [
                        {SplitCount:5},
                        {SplitCount:3,Height:0 }, //第2个成交量图高度设置0隐藏掉
                    ],
            
                    NetworkFilter:  async function(data, callback)	//网络协议回调
                    {
                        console.log('[NetworkFilter] data', data);
                        data.PreventDefault=true;	//设置hqchart不请求数据
                        switch(data.Name)
                        {
                            case "MinuteChartContainer::RequestMinuteData":                 //最新分时图
                                console.log("-------------------- Minute --------------------");
                                //把实例和更新回调保存起来 这样就可以给外部websocket用了
                                if (UpdateMinuteData == undefined) UpdateMinuteData = callback;  //获取更新回调接口
                                if (MinuteChart == undefined) MinuteChart = data.Self;

                                // 计算今天的开盘时间
                                let openTimeCache = openTime ? Number(openTime) : 0;
                                let openTimestamp = parseInt(openTimeCache/60) + "" + openTimeCache%60
                                const hqchartdata = await getHistoryMinute(values.symbol, Number(values.period), 1440, Number(openTimestamp));  //请求数据， 把数据转成hqchart格式数据
                                // 保存全部数据
                                minuteData = hqchartdata;
                                // 保存最新一条数据
                                if (hqchartdata && hqchartdata.stock[0].minute.length > 0) {
                                    stockItem = hqchartdata.stock[0].minute[hqchartdata.stock.length - 1]
                                }
                                // const hqchartdata = minuteTodayJson;            //本地JSON文件数据
                                console.log(hqchartdata);
                                callback(hqchartdata);
                                break;
                            case 'MinuteChartContainer::RequestOverlayHistoryMinuteData':                //叠加股票多日分时数据
                                break;
                            case "MinuteChartContainer::RequestOverlayMinuteData":               //叠加股票最新分时数据
                                break;
                            case 'MinuteChartContainer::RequestHistoryMinuteData':              //多日分时数据
                                break;
                        }
                    }
                }
            }
        }
    }

    // 初始化
    initCanvas() {
        if (this.state.Kline.JSChart) return;

        this.state.Kline.Option.Symbol=this.state.Symbol;
        HQChart.Chart.JSChart.GetResource().FrameLogo.Text=null;
        HQChart.Chart.MARKET_SUFFIX_NAME.GetMarketStatus = (symbol) => {
            return 2; //一直交易
        }

        //当天所有的交易时间点
        HQChart.Chart.JSChart.GetMinuteTimeStringData().GetET = (upperSymbol) => {
            var data = HQChart.Chart.JSChart.GetMinuteTimeStringData().CreateTimeData(TIME_SPLIT);
            return data;
        }
        //X轴刻度设置
        HQChart.Chart.JSChart.GetMinuteCoordinateData().GetETData = (upperSymbol) => {
            //根据不同的品种 返回不同的X轴刻度
            return MINUTE_X_COORDINATE; 
        }
        HQChart.Chart.MARKET_SUFFIX_NAME.GetETDecimal = (symbol)=> { return Number(values.decimal); } // 不同品种，使用不同小数位数
        HQChart.Chart.MARKET_SUFFIX_NAME.IsETShowAvPrice=(symbol)=> {return true; }   //提示信息是否显示均线数值
        HQChart.Chart.MARKET_SUFFIX_NAME.GetETMarketStatus=(symbol)=> { return 2; }   //获取市场状态 0=闭市 1=盘前 2=盘中 3=盘后

        //禁用日志
        HQChart.Chart.JSConsole.Chart.Log=()=>{ }
        HQChart.Chart.JSConsole.Complier.Log=()=>{ }

        chart=HQChart.Chart.JSChart.Init(document.getElementById("time_graph_canvas"));
        chart.SetOption(this.state.Kline.Option);
        this.state.Kline.JSChart=chart;
    }

    // websocket 数据
    websocketKline() {
        if(typeof(WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        }else{
            console.log("您的浏览器支持WebSocket");
        }
        
        let this_ = this;
        let websocket  = new WebSocket("wss://live-wsapp.cd-ex.com/api/bbtc/noauth/websocks/mt5sock");
        //打开事件
        websocket.onopen = function () {
            console.log("websocket已打开");

            // 发送获取产品信息
            let sendInfo = { cmd: 10001, sbl: values.symbol}
            websocket.send(JSON.stringify(sendInfo));
        }
        //发现消息进入
        websocket.onmessage = function (msg) {
            // console.log("websocket已连接");
            // console.log(msg.data);  // 第一次进去会显示：连接成功

            let wsData = JSON.parse(msg.data);
            if(wsData != undefined && wsData.cmd === 51001 && wsData.sbl === values.symbol) {
                if (stockItem != null) {
                    if (!UpdateMinuteData || !MinuteChart) return;    //没有启动好
                    //把收到的数据转成hqchart数据结构
                    stockItem.price = Number(wsData.bid);
                    if (stockItem.high < stockItem.price) {
                        stockItem.high = stockItem.price;
                    }
                    let utime = wsData.utime - 8*60*60
                    stockItem.time = Number(dayjs(utime * 1000).format('HHmmss'));
                    minuteData.stock[0].minute[minuteData.stock[0].minute.length-1] = stockItem;

                    MinuteChart.ChartSplashPaint.IsEnableSplash = false;     //不显示数据下载中的动画
                    UpdateMinuteData(minuteData);
                }
            }

            if (wsData != undefined && wsData.cmd === 10002) {
                console.log('品种信息', wsData.data)
                if(wsData.data.sessions) {
                    timeSplit = [];     //交易时间段
                    minuetCount = 0;    //全天交易分钟数
                    openTime = undefined;   // 开盘时间
                    let closeTime = undefined;  // 收盘时间
                    wsData.data.sessions.map((item, index) => {
                        if (item.type == 0) {
                            if (item.day == dayjs().day()) {
                                if(openTime == undefined) {
                                    openTime = item.open;
                                }
                                closeTime = item.close
                                let info = {Start: item.open, End: item.close};
                                timeSplit.push(info);
                                minuetCount = minuetCount + (item.close - item.open);
                            }
                        }
                    })

                    let minuteCoordiante = {};  //X轴刻度显示配置
                    minuteCoordiante.Count = minuetCount+1;
                    minuteCoordiante.MiddleCount = parseInt(minuetCount/2);   //中心的位置
                    minuteCoordiante.GetData = MINUTE_X_COORDINATE.GetData;
                    
                    // 组装时间刻度
                    let full = [];
                    let avgCount = parseInt(minuetCount/5)
                    let remain = 0; // 每次循环时间刻度余数
                    let pageIndex = 0;
                    timeSplit.forEach((item, index) => {
                        let itemCount = Number(item.End) - Number(item.Start) + remain;
                        let pageCount = Math.ceil(itemCount/avgCount)
                        for(var i=0; i<pageCount; i++) {
                            let itemTime = item.Start + ((i+(index>0?1:0)) * avgCount) - remain;
                            let coordiante = [pageIndex, 1, "RGB(200,200,200)", 
                                (parseInt(itemTime/60).toString().length==1?"0":"") + parseInt(itemTime/60) 
                                + ":" 
                                + ((itemTime%60).toString().length==1?"0":"") + itemTime%60
                            ];
                            pageIndex = pageIndex + avgCount;
                            full.push(coordiante);
                            remain += (i+1) * avgCount <= itemCount ? 0 : (item.End - (i * avgCount));
                        }
                        timeSplit[index].Start = Number(parseInt(item.Start/60) + ((item.Start%60).toString().length==1?"0":"") + item.Start%60);
                        timeSplit[index].End = item.End==1440 ? 2359 : Number((parseInt(item.End/60) + ((item.End%60).toString().length==1?"0":"") + item.End%60));
                    })
                    minuteCoordiante.Full = full;       // 完整模式
                    
                    //当天所有的交易时间点
                    HQChart.Chart.JSChart.GetMinuteTimeStringData().GetET = (upperSymbol) => {
                        var data = HQChart.Chart.JSChart.GetMinuteTimeStringData().CreateTimeData(timeSplit);
                        return data;
                    }
                    HQChart.Chart.JSChart.GetMinuteCoordinateData().GetETData = (upperSymbol) => {
                        //根据不同的品种 返回不同的X轴刻度
                        return minuteCoordiante
                    }
                    UpdateMinuteData(minuteData);
                }
            }
        }
        //关闭事件
        websocket.onclose = function() {
            console.log("websocket已关闭");
            this_.reconnect();
        };
        //发生了错误事件
        websocket.onerror = function() {
            console.log("websocket发生了错误!");
        }
    }

    // WS 重新连接
    async reconnect() {
        // 休眠5秒，重新连接
        await (new Promise((resolve) => setTimeout(resolve, 5000)))

        timeConnect++;
        console.log("第" + timeConnect + "次重连");
        // 进行重连
        this.websocketKline();
    }
    
    componentDidMount() {
        this.initCanvas()
    }
    
    componentDidUpdate() {
        this.initCanvas()
    }

    render() {
        var chartHeight = window.innerHeight-5;
        var chartWidth = window.innerWidth-0;
        var styleText = {
            width: chartWidth+'px', 
            height: chartHeight+'px',
        };
        return (
            <>
                <div style={styleText} id="time_graph_canvas">
                </div>
            </>
        )
    }
}

export default Kline;